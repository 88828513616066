// import React from "react";
// import { Card, Row, Col } from "react-bootstrap";
// import Countdown, { zeroPad } from "react-countdown";
// import { HiOutlineClock } from "react-icons/hi";
// import { NavLink } from "react-router-dom";
// import { pushToDataLayer } from ".././utils/datalayerPush";
// const CommonHorizontalCard = ({
//   data,
//   color,
//   buttonColor,
//   setWidth,
//   backwhite,
//   countdownStyle,
//   setPadding,
//   section_name
// }) => {
//   const renderer = ({ days, hours, minutes, seconds, completed }) => {
//     if (completed) {
//       return (
//         <span>
//           {zeroPad(days)}: {zeroPad(minutes)}: {zeroPad(seconds)}
//         </span>
//       );
//     } else {
//       // Render a countdown
//       return (
//         <span>
//           {zeroPad(days)}: {zeroPad(minutes)}: {zeroPad(seconds)}
//         </span>
//       );
//     }
//   };
//   const handleClick = () => {
//     pushToDataLayer("clicked_card_in_section", {
//       card_name: data.name,
//       section_name: section_name ,
//       page_name: window.location.pathname,
//     }, false);
//   };
//   return (
//     <NavLink to={`/details/${data.url}`} onClick={handleClick}>
//       <Card
//         className="border-0 horizontalCard"
//         style={{
//           background: `${color}`,
//           // padding: `${setWidth === true ? "1px" : "horizontalCard"}`,
//           width: `${setWidth === true ? "98%" : "92%"}`,
//           marginBottom: `${setWidth === false ? "8px" : "8px"}`,
//         }}
//       >
//         <Row>
//           <Col
//             xs={6} md={6}
//             style={{ borderRadius: "6px" }}
//             className={`${backwhite === true ? "order-1" : "order-2"}`}
//           >
//             <div
//               className="cardImage"
//               style={{
//                 backgroundImage: `url(${data.image.replaceAll(" ", "%20")} )`,
//                 padding: "2px",
//               }}
//             ></div>
//           </Col>
//           <Col
//             xs={6} md={6} /* style={{padding: '0px'}} */
//             className={`${backwhite === true ? "order-2" : "order-1"}`}
//           >
//             <div className="cardData">
//               {data.countdown !== undefined &&
//                 data.countdown !== "" &&
//                 data.countdown !== "Invalid date" && (
//                   <div
//                     className="orange_box d-flex gap-1"
//                     style={{
//                       background: `${countdownStyle.background}`,
//                       color: `${countdownStyle.color}`,
//                       /* padding: `${setPadding}`, */
//                     }}
//                   >
//                     <HiOutlineClock size={18} className="d-none d-lg-block" />
//                     <Countdown
//                       className="countdown"
//                       date={data.countdown}
//                       renderer={renderer}
//                     />
//                   </div>
//                 )}
//               <div className="SellPrice" style={{ color: `${buttonColor}` }}>
//                 {data.display_price.slice(0,4)}<span /* style={{fontSize: '18px'}} */>{data.display_price.slice(3, (data.display_price.length))}</span>
//               </div>
              
//               <div className="descrip" style={{height: '35px'}}>{data.name}</div>
//               <div
//                 className="discountPercent pt-2"
//                 style={{ color: `${buttonColor}` }}
//               >
                
//               </div>
//             </div>
//           </Col>
//         </Row>
//       </Card>
//     </NavLink>
//   );
// };

// export default CommonHorizontalCard;
import React from "react";
import { Card, Row, Col, Badge } from "react-bootstrap";
import Countdown from "react-countdown";
import { HiOutlineClock } from "react-icons/hi";
import { NavLink } from "react-router-dom";
import { pushToDataLayer } from "../utils/datalayerPush";

const CommonHorizontalCard = ({ data,
  color,
  buttonColor,
  whiteColor,
  setWidth,
  backwhite,
  countdownStyle,
  setPadding,
  section_name,
  deals,}) => {

  const renderer = ({ hours, minutes, seconds }) => (
    <span>
      {`${hours.toString().padStart(2, "0")}h : ${minutes
        .toString()
        .padStart(2, "0")}m : ${seconds.toString().padStart(2, "0")}s`}
    </span>
  );

  const handleClick = () => {
    pushToDataLayer(
      "clicked_card_in_section",
      {
        card_name: data.name,
        section_name,
        page_name: window.location.pathname,
      },
      false
    );
  };

  return (
    <NavLink to={`/details/${data.url}`} onClick={handleClick} className="text-decoration-none">
      <Card className="horizontalCard"
      style={{
        backgroundColor: `${color}`}}
      >
        <Row className="g-0 align-items-center">
          <Col xs={4} className="cardImage" style={{ backgroundColor: color  }}>
            <img src={data.image} alt={data.name} className="img-fluid" style={{  
            width: "100%",
            backgroundColor: "transparent",
            objectFit: "contain",
            paddingLeft: "2px"
            }} 
            />
          </Col>
          <Col xs={8}>
            <div className="cardData">
            {data.countdown && !isNaN(new Date(data.countdown).getTime())  &&
              (
                <div className="orange_box mb-2 d-inline-flex align-items-center">
            
                  <HiOutlineClock size={14} className="me-1" />
                  <Countdown date={data.countdown} renderer={renderer} />
           
                </div>
              )}
              <div className="descrip">{data.name}</div>
              <div className="SellPrice mt-1">
               <span style={{ fontSize: '15px', fontWeight: '500', color: "#43494B" }}>BHD</span> <span>{data.display_price.replace("BHD ", "")}</span>
              </div>
              <div className="d-flex align-items-center gap-2 mt-1">
                <div className="oldPrice"
                style={{ color: `#43494B`, whiteSpace: 'nowrap', fontSize: '15px', fontWeight: "500" }}
                >{data.old_price.replace("BHD ", "")}</div>
                <div className="discountPercent"
                 style={{ color: `#33B056`, whiteSpace: 'nowrap', fontSize: "14px", fontWeight: "600", backgroundColor: "#FFFFFF", borderRadius: "6px", padding: "4px 8px" }}
                >{data.percentage}% OFF</div>
              </div>
            </div>
          </Col>
        </Row>
      </Card>
    </NavLink>
  );
};

export default CommonHorizontalCard;
