import React, { useEffect, useContext, useState } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import { GlobalCart } from "../App";
import jwt from "jwt-decode";
import { toast } from "react-toastify";
import { ThreeDots } from 'react-loader-spinner';

const AddToCartAPI = ({ cartDetail, cartClick, setCartClick }) => {
  const { getCartData, userId, setcarterrMsg } = useContext(GlobalCart);
  const cookies = new Cookies();
  const [isLoading, setIsLoading] = useState(false);
  var userData = {};

  const getUserdata = () => {
    if (cookies.get("oscad") === undefined) {
      userData = {
        product_id: cartDetail.id,
        ip_address: 0,
        user_id: userId,
        quantity: cartDetail.quantity,
      };
    } else {
      userData = {
        product_id: cartDetail.id,
        ip_address: cookies.get("oscad"),
        user_id: userId,
        quantity: cartDetail.quantity,
      };
    }
    addTocartFunc(userData);
  };

  const removeAnimation = () => {
    document.getElementById("shopCart").style.animation = "unset"
  }

  const addTocartFunc = (userData, e) => {
    setIsLoading(true);
    axios
      .post("/api/AddToCart", userData)
      .then((response) => {
        var user_id = 0;
        if (cookies.get("jwt_token") !== undefined) {
          var decoded = jwt(cookies.get("jwt_token"));
          user_id = decoded.user_id;
        }

        cookies.set("oscad", response.data.data.ip_address, {
          httpOnly: false,
          path: "/",
          maxAge: 60 * 60 * 24 * 5,
        });

        if (response.data.data.msg == undefined) {
          setcarterrMsg("");
          toast.success("Item Added to Cart", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          setIsLoading(false);
        } else {
          setcarterrMsg(response.data.data.msg);
          toast.error(response.data.data.msg, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          setIsLoading(false);
        }
        getCartData(user_id);
        // setCartClick(false);
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoading(false);
        toast.error("Failed to add item to cart", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getUserdata();
  }, []);

  return (
    <div>
      {isLoading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0,0,0,0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
          }}
        >
          <ThreeDots
            type="ThreeDots"
            color="#FFFFFF"
            height={50}
            width={50}
            visible={true}
          />
        </div>
      )}
    </div>
  );
};

export default AddToCartAPI;
